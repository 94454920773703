import { css } from 'styled-components';
import { colors } from '../../style-utilities/variables';
export default css`
  .flyoutTitle {
    color: ${colors.primary};
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 8px;
  }
`;
