import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/FlyoutBasic.styles';
import { breakpoints } from '../../src_american-beauty/style-utilities/variables';

const StyledFlyout = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: relative;
  @media (min-width: ${breakpoints.desktop}) {
  }
  /* flyout appears in many places and styling one affects the others */
  /* locationId set to parent container followed by -flyout is attempt to have  */
  /* one style not affect the others. */
  .content {
    background: white;
    box-shadow: 1px 2px 10px #aaa;
    position: relative;
    z-index: 300;
    padding: 10px 20px;
  }

  .caret {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
    height: 0;
    right: 48%;
    top: -12px;
    width: 0;
    position: absolute;
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 10px;
    padding: 0;
    background: transparent;
    border: none;
    @media (min-width: 420px) {
      right: 10px;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const FlyoutBasic = ({ children, styles, onClose, locationId, className }) => {
  return (
    <StyledFlyout {...styles} className={className}>
      <div className="content" id={locationId}>
        <div className="caret" />
        <button className="close-button" onClick={onClose}>
          X
        </button>
        {children}
      </div>
    </StyledFlyout>
  );
};

export default FlyoutBasic;
