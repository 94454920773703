import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/RecipeCard.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';

import FavoritesService from '../services/favorites.service';
import UserService from '../services/user.service';
import ReviewsService from '../services/reviews.service';

import FlyoutBasic from '../components/FlyoutBasic';
import FlyoutContentNotAuthed from '../components/FlyoutContentNotAuthed';

import ReviewsRating from '../components/Reviews/ReviewRating';

const Div = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 270px;
  margin: auto;
  transition: box-shadow 0.3s;
  .opacity-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  .title {
    margin-bottom: 0;
    font-size: 16px;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const ImgContainer = styled.div`
  vertical-align: middle;
  display: flex;
  position: relative;
  background: ${props => props.bg};
  background-size: cover;
  background-position: center center;
  height: 220px;
  width: 270px;
  margin-bottom: 16px;
  .favorite,
  .watch,
  .tags {
    position: absolute;
    text-transform: capitalize;
  }
  .tag {
    text-transform: capitalize;
  }
  .favorite {
    background: transparent;
    border: none;
    right: 0;
    top: 5px;
    right: 5px;
    font-size: 14px;
    svg {
      color: white;
    }
  }
  .watch {
    background: transparent;
    border: none;
    left: 0;
    bottom: 12px;
    color: white;
  }
  .inline {
    display: inline-block;
  }
`;

const InfoContainer = styled.div`
  display: block;
  height: auto;
  width: 100%;
  text-align: left;
  color: #333333;
  .info-section {
    padding-left: 10px;
  }
  p {
    margin-top: 8px;
  }
`;

class RecipeCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthed: false,
      isFavorited: false,
      user: {},
      reviewsCount: {}
    };

    this.favoriteService = new FavoritesService();
    this.userService = new UserService();
    this.reviewsService = new ReviewsService();
  }

  componentDidMount() {
    const user = this.userService.getAuthenticatedUser();
    this.setState({
      user: user,
      isAuthed: user ? true : false
    });

    this.refresh();
  }

  refresh = () => {
    this.getFavorited();
    this.getReviewCount();
  };

  getFavorited = () => {
    const user = this.userService.getAuthenticatedUser();

    const type = 'recipes';
    const item = {
      slug: this.props.slug
    };
    if (user && item) {
      this.favoriteService.getFavorite(user, item, type).then(data => {
        if (data && data.userId) {
          this.setState({
            isFavorited: true
          });
        } else {
          this.setState({
            isFavorited: false
          });
        }
      });
    }
  };

  getReviewCount = () => {
    const type = 'recipes';
    const slug = this.props.slug;

    this.reviewsService.getReviewsCountForItem(type, slug).then(data => {
      this.setState({ reviewsCount: data || {} });
    });
  };

  componentDidUpdate(prevPros) {
    if (this.props.refreshFavorites !== prevPros.refreshFavorites) {
      this.refresh();
    }
    if (this.props.authenticatedUser !== prevPros.authenticatedUser) {
      this.setState({
        isAuthed: true
      });
    }
  }

  toggleFavorite = event => {
    event.preventDefault();
    const user = this.userService.getAuthenticatedUser();
    const type = 'recipes';
    const item = {
      slug: this.props.slug
    };
    const confirmMsg =
      'Are you sure you want to remove this recipe from your list?';

    const doToggle = () => {
      this.favoriteService.toggleFavorite(user, item, type).then(result => {
        if (result) {
          this.setState(
            {
              isFavorited: result.slug ? true : false
            },
            () => {
              // here we get the total amount of favs
              this.favoriteService
                .getFavoritesForUser(user, type)
                .then(result => {
                  if (result) {
                    this.props.updateFavoritesCount(result.length);
                  }
                });
              if (this.props.onFavoriteCallback) {
                this.props.onFavoriteCallback();
              }
            }
          );
        }
      });
    };

    if (user && item) {
      if (this.state.isFavorited) {
        if (window.confirm(confirmMsg)) {
          doToggle();
        }
      } else {
        doToggle();
      }
    } else if (!user) {
      //show no auth flyout
      this.setState({
        isAuthed: false
      });

      this.toggleShowNoAuthFlyout();
    }
  };

  toggleShowNoAuthFlyout = () => {
    this.setState(prevState => ({
      showNoAuthFlyout: prevState.showNoAuthFlyout ? false : true
    }));
  };

  render() {
    const {
      id,
      title,
      imageUrl,
      mainIngredient,
      cuisine,
      meal,
      cookTime,
      slug,
      ...remainProps
    } = this.props;
    return (
      <Link to={`/recipes/${slug}`}>
        <Div {...remainProps}>
          <ImgContainer className="ImgContainer" bg={`url(${imageUrl})`}>
            <div className="opacity-layer">
              <button className="favorite" onClick={this.toggleFavorite}>
                {this.state.isFavorited ? (
                  <FontAwesomeIcon
                    icon={faHeartSolid}
                    style={{ width: '16px', height: '16px' }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faHeart}
                    style={{ width: '16px', height: '16px' }}
                  />
                )}
              </button>
              <span className="inline">
                <FlyoutBasic
                  styles={{
                    visible:
                      !this.state.isAuthed && this.state.showNoAuthFlyout,
                    height: '250px'
                  }}
                  onClose={event => {
                    event.preventDefault();
                    this.toggleShowNoAuthFlyout();
                  }}
                  locationId="recipe-card-flyout"
                >
                  <FlyoutContentNotAuthed title="Save & Favorite Recipes" />
                </FlyoutBasic>
              </span>
              <button className="watch">
                {/* <FontAwesomeIcon icon={faPlayCircle} /> WATCH */}
              </button>
              <div className="tags">
                {/* If data does not exist, don't display an empty tag */}
                {mainIngredient ? (
                  <span key={mainIngredient} className="tag">
                    {mainIngredient === 'veggie'
                      ? mainIngredient.replace('veggie', 'vegetarian')
                      : mainIngredient}
                  </span>
                ) : (
                  ''
                )}
                {cookTime && cookTime !== '-' ? (
                  <span className="cook-time">
                    {cookTime.length > 21
                      ? ReactHtmlParser(cookTime.substring(0, 21) + '...')
                      : ReactHtmlParser(cookTime)}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          </ImgContainer>
          <InfoContainer>
            <div className="info-section">
              <h2 className="title">{ReactHtmlParser(title)}</h2>
              {/* <p key={cuisine} style={{ textTransform: "capitalize" }}>
              {cuisine}
            </p> */}
            </div>
            <p>
              {this.state.reviewsCount.count >= 5 ? (
                <span>
                  <ReviewsRating
                    initialRating={this.state.reviewsCount.avgRating}
                  />
                  <span className="review-total">
                    &nbsp; {this.state.reviewsCount.avgRating}/5 (
                    <span className="review-count">
                      {this.state.reviewsCount.count} Reviews
                    </span>
                    )
                  </span>
                </span>
              ) : null}
            </p>
          </InfoContainer>
        </Div>
      </Link>
    );
  }
}

export default RecipeCard;
