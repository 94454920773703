import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '../style-utilities/variables';
import RegularButton, { Button } from './RegularButton';
import { UiStateConsumer } from '../context/UiStateContext';
import ComponentStyles from '../brand-styles/components/FlyoutContentNotAuthed.styles';
const FlyoutContentNotAuthedStyle = styled.div`
  background: white;
  margin: 10px auto;
  display: block;
  text-align: center;

  button {
    display: inline-block;
    margin: 20px 10px;
    width: 145px;
  }

  ${Button} {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  p {
    color: black;
  }
  ${ComponentStyles};
`;

class FlyoutContentNotAuthed extends Component {
  componentDidMount() {}

  render() {
    const { title } = this.props;

    return (
      <UiStateConsumer>
        {uiStateContext => {
          if (this.props.siteTitle && this.props.siteTitle.includes('-es')) {
            return (
              <FlyoutContentNotAuthedStyle>
                <span className="flyoutTitle">{title}</span>
                <p>
                  Guarde, imprima y envíe por correo electrónico listas de
                  compras personalizadas
                </p>
                <p>Guardar y recetas favoritas</p>
                <p>Califique y revise las recetas</p>
                <RegularButton
                  onClick={event => {
                    event.preventDefault();
                    uiStateContext.toggleModal('login');
                  }}
                >
                  Iniciar Sesión
                </RegularButton>
                <RegularButton
                  onClick={event => {
                    event.preventDefault();
                    uiStateContext.toggleModal('signUp');
                  }}
                >
                  Regístrese
                </RegularButton>
              </FlyoutContentNotAuthedStyle>
            );
          } else {
            return (
              <FlyoutContentNotAuthedStyle>
                <span className="flyoutTitle">{title}</span>
                <p>Save, print, and email custom shopping lists</p>
                <p>Save and favorite recipes</p>
                <p>Rate and review recipes</p>
                <RegularButton
                  onClick={event => {
                    event.preventDefault();
                    uiStateContext.toggleModal('login');
                  }}
                >
                  Log In
                </RegularButton>
                <RegularButton
                  onClick={event => {
                    event.preventDefault();
                    uiStateContext.toggleModal('signUp');
                  }}
                >
                  Sign Up
                </RegularButton>
              </FlyoutContentNotAuthedStyle>
            );
          }
        }}
      </UiStateConsumer>
    );
  }
}

export default FlyoutContentNotAuthed;
