import { css } from 'styled-components';
import { breakpoints, colors } from '../../style-utilities/variables';
export default css`
  position: absolute;
  top: 60px;
  width: auto;

  @media (min-width: 375px) {
    width: 344px;
  }
  @media (min-width: ${breakpoints.tablet}) {
    width: 370px;
  }
  @media (min-width: 900px) {
    width: 390px;
  }

  #recipe-detail-widget-flyout {
    left: -4.8em;
    top: 6em;
  }
  #recipe-detail-btnCont-flyout {
    top: 4.3em;
  }
  #recipe-collection-detail-flyout {
    top: 5em;
  }

  #recipe-card-flyout {
    left: -10em;
    top: 2em;
  }
  #bigrecipe-flyout {
    top: 1.5em;
    left: 2.6em;
  }
  @media (min-width: 425px) {
    #recipe-collection-detail-flyout {
      left: 8%;
    }
  }
  @media (min-width: 710px) {
    #recipe-collection-detail-flyout {
      top: 4em;
      left: 48%;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    #recipe-detail-widget-flyout {
      left: 7em;
      top: 0;
    }
    #recipe-detail-btnCont-flyout {
      left: -8%;
    }
    #recipe-collection-detail-flyout {
      top: 2em;
      left: -5%;
    }
    #subrecipe-flyout {
      left: -7.7em;
      top: 2.5em;
    }
    #seasonal-recipes-flyout {
      top: 2.5em;
      left: 6.4em;
    }
    #recipe-card-flyout {
      left: -4em;
      top: 2em;
    }
  }
  @media (min-width: 900px) {
    #recipe-detail-widget-flyout {
      left: 6.5em;
      top: 0;
    }
    #recipe-detail-btnCont-flyout {
      left: -18%;
    }
    #subrecipe-flyout {
      left: -7.7em;
    }
    #seasonal-recipes-flyout {
      left: 9em;
    }
    #bigrecipe-flyout {
      left: 4.5em;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    #subrecipe-flyout {
      left: -6.2em;
    }
    #seasonal-recipes-flyout {
      top: 2.5em;
      left: 14.5em;
    }
    #bigrecipe-flyout {
      left: 8.5em;
    }
  }
  @media (min-width: 1400px) {
    #subrecipe-flyout {
      left: -4.2em;
    }
    #seasonal-recipes-flyout {
      top: 2.5em;
      left: 22.5em;
    }
    #bigrecipe-flyout {
      left: 14.5em;
    }
  }
  @media (min-width: 2560px) {
    #subrecipe-flyout {
      left: 2.8em;
    }
    #seasonal-recipes-flyout {
      top: 2.5em;
      left: 50.5em;
    }
    #bigrecipe-flyout {
      left: 35.5em;
    }
  }
`;
