import { css } from 'styled-components';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';

export default css`
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  margin: 0 auto;
  .favorite {
    padding: 0;
  }
  .tags {
    bottom: 10px;
    right: 5px;
    .tag,
    .cook-time {
      padding: 5px;
    }
    .tag {
      background: ${colors.white};
      color: ${colors.primary};
      margin-right: 5px;
    }
    .cook-time {
      background: ${colors.primary};
      color: ${colors.white};
    }
  }
  .ImgContainer {
    /* display: flex; */
    margin: 0 auto;
    margin-bottom: 16px;
  }
  /* .InfoContainer {
    display: flex;
    justify-content: center !important;
  } */
`;
