import React, { Component } from 'react';
import Rating from 'react-rating';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';

class ReviewsRating extends Component {
  render() {
    return (
      <Rating
        emptySymbol={
          <FontAwesomeIcon
            icon={faStar}
            style={{ width: '18px', height: '16px' }}
          />
        }
        fullSymbol={
          <FontAwesomeIcon
            icon={faStarSolid}
            style={{ width: '18px', height: '16px' }}
          />
        }
        initialRating={this.props.initialRating}
        readonly
        fractions={1}
      />
    );
  }
}

export default ReviewsRating;
